import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';
import { Suspense, useState } from 'react';

import { Contact } from '../../../generated/types';
import { Colors } from '../../../styles/colors';

import { Form } from '../../atoms/Form/Form';
import { PrimaryButton, SecondaryButton, WrapperButtons } from '../../atoms/Button/Buttons';
import { H2 } from '../../atoms/Typography/Headings';
import { InfoAlert } from '../../atoms/InfoAlert';
import { TextInput } from '../../atoms/Form/TextInput';
import { Copy } from '../../atoms/Typography';
import {
  AlertIcon,
  CheckIcon,
  ChevronRightIcon,
  EditIcon,
  LinkIcon,
  MoneyBillIcon,
  OfficeBuildingIcon,
  PeopleFullIcon,
  SearchIcon,
  SpinnerIcon,
  SuitcaseIcon,
} from '../../atoms/Icons';
import { TabController, TabPanel } from '../../atoms/Tabs';
import { ErrorBoundary } from '../../molecules/ErrorBoundary';
import { SmallLoader } from '../../atoms/Loader';
import { Spacer } from '../../atoms/Spacer/Spacer';
import { StepsInProgress } from '../../molecules/StepsInProgress';

export const ImportJobModal = ({
  handleCancel,
  handleJobUrlSubmit,
  handleJobContentSubmit,
  handleJobImportedClick,
  jobUuid,
}: ImportJobModalProps) => {
  const [isImporting, setIsImporting] = useState(false);
  const [tab, setTab] = useState('url');

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      if (tab === 'url' && data.url) {
        setIsImporting(true);
        await handleJobUrlSubmit(data.url);
      }
      if (tab === 'content' && data.description) {
        setIsImporting(true);
        await handleJobContentSubmit(data.description);
      }
    } catch (error) {
      console.error(error);
      setIsImporting(false);
    }
  };

  let steps = [
    'Filling agency information',
    'Extracting company information',
    'Collecting work requirements',
    'Finding rates',
    'Creating Job Application',
    'Finishing up',
    'Finishing up',
    'Finishing up',
    'Finishing up',
    'Finishing up',
    'Finishing up',
    'Finishing up',
    'Finishing up',
    'Error',
  ];

  let icons = [
    <PeopleFullIcon color={Colors.Contrast} size={3} />,
    <OfficeBuildingIcon color={Colors.Contrast} size={3} />,
    <SuitcaseIcon color={Colors.Contrast} size={3} />,
    <MoneyBillIcon color={Colors.Contrast} size={3} />,
    <EditIcon color={Colors.Contrast} size={3} />,
    <SpinnerIcon />,
    <SpinnerIcon />,
    <SpinnerIcon />,
    <SpinnerIcon />,
    <SpinnerIcon />,
    <SpinnerIcon />,
    <SpinnerIcon />,
    <SpinnerIcon />,
    <AlertIcon color={Colors.Error} size={3} />,
  ];

  let descriptions = [
    'Please wait while our AI does some digital yoga to extract the agency information.',
    'Please wait while we summon the job application wizard to collect the company information.',
    'Hold on tight! Our AI is sharpening its digital pencils to collect the work requirements.',
    "Keep calm and let our AI do its magic. It's currently brewing the perfect cup of job opportunity for you.",
    'Sit back, relax, and let our AI sprinkle some digital fairy dust to make your job application shine!',
    "Almost there... Just a few more magical incantations and you'll be on your way to the job application kingdom.",
    "Shouldn't be long now... Our digital hamsters are racing on their wheels to power up your job application.",
    'Just a moment... Our virtual minions are diligently working to create the job application of your dreams.',
    'Hang tight... Our digital elves are sprinkling some coding glitter to make your job application sparkle.',
    "Just a bit longer... Our AI is practicing its job application dance moves. It'll be ready to boogie with you soon!",
    "Keep calm and let our AI do its magic. It's currently brewing the perfect cup of job opportunity for you.",
    "Hold on... Our AI is busy crafting your job application masterpiece. It'll be worth the wait, we promise!",
    'Patience, please... Our AI is juggling job opportunities like a digital circus performer. Your turn is up next!',
    'An error occurred while importing the job. Please refresh the page and try again. We apologize for the inconvenience.',
  ];

  if (tab === 'url') {
    // insert a step to the beginning of the array
    steps.unshift('Extracting the Web Page');
    icons.unshift(<SearchIcon color={Colors.Contrast} size={3} />);
    descriptions.unshift('Hold onto your hats! Our bot is on a wild adventure to extract information from a webpage.');
  }

  if (tab === 'url') {
    // insert a step to the beginning of the array
    steps.unshift('Accessing the URL');
    icons.unshift(<LinkIcon color={Colors.Contrast} size={3} />);
    descriptions.unshift(
      'Just a moment... Our bot is channeling its inner Spider-Man to swing into action and connect to the specified URL.'
    );
  }

  if (jobUuid) {
    steps = ['All set!'];
    icons = [<CheckIcon color={Colors.Contrast} size={3} />];
    descriptions = ['We can review the job application now.'];
  }

  return (
    <>
      <H2>Import Job</H2>
      {isImporting ? (
        <>
          {jobUuid ? (
            <>
              <StepsInProgress
                key={!jobUuid}
                icons={icons}
                steps={steps}
                descriptions={descriptions}
                duration={3000}
                isLoadingComplete={true}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PrimaryButton
                  inline={true}
                  onClick={handleJobImportedClick}
                  iconRight={<ChevronRightIcon color={Colors.White} size={1} />}
                >
                  Review Job Application
                </PrimaryButton>
              </div>
              <Spacer y={16} />
            </>
          ) : (
            <>
              <StepsInProgress icons={icons} steps={steps} descriptions={descriptions} duration={3000} />
              <Spacer y={80} />
            </>
          )}
        </>
      ) : (
        <>
          <InfoAlert>
            {tab === 'url' ? (
              <>
                <Copy>You can import a job from an URL</Copy>
                <Copy styleLevel={2} marginBottom={8}>
                  Let our AI Assistant do the heavy lifting for you. Simply paste a job posting URL, and our AI
                  Assistant will automatically extract the information and create a job application in your Jobs Board.
                </Copy>
                <Copy styleLevel={3}>Example: https://online-recruiter-position.com/jobs/company/position</Copy>
              </>
            ) : (
              <>
                <Copy>You can import a job from a description</Copy>
                <Copy styleLevel={2} marginBottom={8}>
                  Let our AI Assistant do the heavy lifting for you. Simply paste the job description, and our AI
                  Assistant will automatically extract the information and create a job application in your Jobs Board.
                </Copy>
              </>
            )}
          </InfoAlert>
          <Form onSubmit={onSubmit} validationSchema={validationSchema}>
            <TabController
              headers={[
                { id: 'url', label: 'url' },
                { id: 'content', label: 'content' },
              ]}
              initialActiveId={'url'}
              onTabChanged={(tabId) => {
                setTab(`${tabId}`);
              }}
            >
              <TabPanel $tabId="url">
                <Suspense fallback={<SmallLoader />}>
                  <ErrorBoundary>
                    <Spacer y={16} />
                    <TextInput name="url" fullWidth type="url" label="URL" defaultValue="" noMargin={true} />
                    <Spacer y={32} />
                    <WrapperButtons>
                      <SecondaryButton inline onClick={handleCancel}>
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton
                        type="submit"
                        inline={true}
                        iconRight={<ChevronRightIcon color={Colors.White} size={1} />}
                      >
                        Import
                      </PrimaryButton>
                    </WrapperButtons>
                  </ErrorBoundary>
                </Suspense>
              </TabPanel>
              <TabPanel $tabId="content">
                <Suspense fallback={<SmallLoader />}>
                  <ErrorBoundary>
                    <Spacer y={16} />
                    <TextInput
                      name="description"
                      defaultValue=""
                      type="textarea"
                      label="Description"
                      multiline={true}
                      minRows={10}
                      noMargin={true}
                      placeholder="Drop the job description here"
                    />
                    <Spacer y={32} />
                    <WrapperButtons>
                      <SecondaryButton inline onClick={handleCancel}>
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton
                        type="submit"
                        inline={true}
                        iconRight={<ChevronRightIcon color={Colors.White} size={1} />}
                      >
                        Import
                      </PrimaryButton>
                    </WrapperButtons>
                  </ErrorBoundary>
                </Suspense>
              </TabPanel>
            </TabController>
          </Form>
        </>
      )}
    </>
  );
};

const validationSchema = Yup.object().shape({
  url: Yup.string(),
  description: Yup.string(),
});

type FormData = Yup.InferType<typeof validationSchema>;

interface ImportJobModalProps {
  contact?: Contact;
  handleJobUrlSubmit: (a: any) => void;
  handleJobContentSubmit: (a: any) => void;
  handleCancel: (a: any) => void;
  handleJobImportedClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  jobUuid?: string;
}
