import styled, { keyframes } from 'styled-components';
import { Colors } from '../../../styles/colors';

const dotAnimation = keyframes`
  0% {
      content: "",
  }

  25% {
      content: "."
  }

  50% {
      content: ".."
  }

  75% {
      content: "...",
  }

  to {
      content: "...."
  }
`;

const rotationAnimation = keyframes`
    to {
        transform: rotate(360deg)
    }
`;

const fadeInAnimation = keyframes`
  0% {
      opacity: 0
  }

  to {
      opacity: 1
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px dotted ${Colors.ContrastUltraLightest};
  border-radius: 8px;
  padding: 32px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 16px;
`;

export const IconInner = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: ${Colors.Primary};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed ${Colors.Primary};
`;

export const IconSpinner = styled.div`
  width: 80px;
  height: 80px;
  position: absolute;
  animation: ${rotationAnimation} 2s infinite;
  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 3px solid ${Colors.Primary};
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  color: ${Colors.ContrastDarkest};
  text-align: center;
`;

export const TitleInner = styled.div`
  text-align: left;
  animation: ${fadeInAnimation} 1s;
  &::after {
    display: inline-block;
    content: '...';
    animation: ${dotAnimation} steps(1, end) 2s infinite;
    width: 32px;
    text-align: left;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DescriptionInner = styled.div`
  width: 60%;
  height: 48px;
  text-align: center;
  color: ${Colors.GreyDarkest};
`;
