import { StepsInProgressProps } from './StepsInProgress.types';
import {
  Container,
  IconWrapper,
  TitleWrapper,
  DescriptionWrapper,
  TitleInner,
  IconInner,
  IconSpinner,
  DescriptionInner,
} from './StepsInProgress.styles';
import { useEffect, useState } from 'react';
import { Spacer } from '../../atoms/Spacer/Spacer';

export const StepsInProgress = ({
  steps,
  descriptions,
  icons,
  duration,
  isLoadingComplete = false,
}: React.PropsWithChildren<StepsInProgressProps>) => {
  const [step, setStep] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setStep((prevStep) => {
        const nextStep = (prevStep + 1) % steps.length;
        if (nextStep === steps.length - 1) {
          clearInterval(interval);
        }
        return nextStep;
      });
    }, duration);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <IconWrapper>
        <IconInner>{icons[step]}</IconInner>
        {isLoadingComplete ? null : <IconSpinner />}
      </IconWrapper>
      <TitleWrapper>{isLoadingComplete ? <>{steps[step]}</> : <TitleInner>{steps[step]}</TitleInner>}</TitleWrapper>
      <Spacer y={16} />
      <DescriptionWrapper>
        <DescriptionInner>{descriptions[step]}</DescriptionInner>
      </DescriptionWrapper>
    </Container>
  );
};
